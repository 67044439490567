<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import moment from "moment";
import ServiceTicketModal from "@/components/modals/serviceTickets/modalViewServiceTicket.vue";
import EditServiceTicket from '@/components/modals/serviceTickets/modalEditServiceTicket.vue';
import StatusServiceTicket from '@/components/modals/serviceTickets/modalStatusServiceTicket.vue';
import DatePicker from "vue2-datepicker";
import dayjs from 'dayjs';
import ServiceTicketService from "@/services/ServiceTickets";



/**
 * Service Tickets component
 */
export default {
  components: { Layout, PageHeader, ServiceTicketModal,EditServiceTicket,StatusServiceTicket,DatePicker },
  page: {
    title: "Service Tickets",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    const today = dayjs();
    const sevenDaysAgo = dayjs().subtract(7, "day");
    return {
      title: "Service Tickets",
      items: [
        {
          text: "Service Tickets",
          active: true,
        },
      ],
      filterInput: {
        from_date: sevenDaysAgo.format("YYYY-MM-DD"), // Default: 7 days ago
        to_date: today.format("YYYY-MM-DD"), // Default: Today
        status: '',
      },
      statusOptionsFilter: [
        { value: 0, text: '0-Сервис тикетот е одбиен' },
        { value: 1, text: '1-Нов сервис тикет' },
        { value: 2, text: '2-Сервис тикетот е отворен' },
        { value: 3, text: '3-Уредот е спремен за сервисирање' },
        { value: 4, text: '4-Уредот се сервисира' },
        { value: 5, text: '5-Уредот е сервисиран' },
        { value: 6, text: '6-Уредот чека на испорака' },
        { value: 7, text: '7-Уредот е доставен' }
      ],
      isBusy: false,
      showDateInfo: true,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "created_date",
      sortDesc: true,
      fields: [
        {key: "ticket_id", label: "Ticket ID", class: ["text-dark", "fw-bold"]},
        {key: "first_name", label: "First Name"},
        {key: "last_name", label: "Last Name"},
        {key: "company_name", label: "Company Name"},
        {key: "phone", label: "Phone"},
        {key: "product_model", label: "Product Model"},
        {key: "statusText", label: "Status", sortable: true},
        {key: "statusActions", label:""},
        {key: "created_date", label: "Date Created", sortable: true},
        { key: "actions", label: "Actions" },

      ],
      showModal: false,
      selectedTicketId: null,
      selectedTicketDetails: null,
    };
  },
  async created() {
    this.applyFilters();
  },
  mounted() {
    this.filterInput.from_date = '';
    this.filterInput.to_date = '';
    this.filterInput.status='';
  },
  methods: {
    async getServiceTickets(filterString) {
      if(!filterString)
      {
        filterString='';
      }
      try {
        const response = await ServiceTicketService.getServiceTickets(filterString);

        const data = response.data.data;
        // console.log(data);
        this.tableData = data.map((ticket) => ({
          ticket_id: ticket.id,
          first_name: ticket.first_name,
          last_name: ticket.last_name,
          guarantee: ticket.guarantee,
          company_name: ticket.company_name,
          phone: ticket.phone,
          email: ticket.email,
          city: ticket.city,
          address: ticket.address,
          product_model: ticket.product_model,
          product_serial: ticket.product_serial,
          fiscal_slip: ticket.fiscal_slip,
          guarantee_list_scan:ticket.guarantee_list_scan,
          description:ticket.description,
          status: ticket.status,
          statusText: this.getStatusText(ticket.status),
          created_date: ticket.created,
        }));

        this.totalRows = this.tableData.length;
      } catch (error) {
        console.error("Error fetching service tickets:", error);
        this.tableData = [];
        this.totalRows = 1;
      }
    },
    getStatusText(status) {
      const statusOptions = {
        0: '0-Сервис тикетот е одбиен',
        1: '1-Нов сервис тикет',
        2: '2-Сервис тикетот е отворен',
        3: '3-Уредот е спремен за сервисирање',
        4: '4-Уредот се сервисира',
        5: '5-Уредот е сервисиран',
        6: '6-Уредот чека на испорака',
        7: '7-Уредот е доставен'
      };
      return statusOptions[status] || 'Unknown Status';

       
    },
    // applyFilter() {
    //   // this.getServiceTickets();  // Re-fetch with applied filters
    // },
    applyFilters() {
      const formattedFromDate = dayjs(this.filterInput.from_date).format("YYYY-MM-DD");
      const formattedToDate = dayjs(this.filterInput.to_date).format("YYYY-MM-DD");
      let filterString = '';

      if (this.filterInput.from_date) {
        filterString += `from_date=${formattedFromDate}&`;
      }

      if (this.filterInput.to_date) {
        filterString += `to_date=${formattedToDate}&`;
      }
      // alert(`Filter input status is ${this.filterInput.status}`);
      if (this.filterInput.status || this.filterInput.status === 0) {
        // filterString += `status=${this.filterInput.status}&`;
         filterString += `status=${this.filterInput.status}&`;
      }

      if (filterString) {
        // Remove the last '&' character
        filterString = filterString.slice(0, -1);
      }
      // console.log(`Filter String: ${filterString}`);
      this.getServiceTickets(filterString);
    },
    openModal(ticket) {
      // console.log("Selected Ticket:", ticket);
      this.selectedTicketId = ticket.ticket_id;
      // console.log("Modal Ticket ID:", this.selectedTicketId);
      this.showModal = true;
      // console.log("Show Modal:", this.showModal);
    },
    openEditModal(ticket) {
      this.selectedTicketDetails = ticket;
      this.$bvModal.show('edit_service_ticket');
    },
    openStatusModal(ticket) {
      this.selectedTicketDetails = ticket;
      this.$bvModal.show('status_service_ticket');
    },
    closeModal() {
      this.showModal = false; // Close the modal
      this.selectedTicketId = null; // Clear the selected ticket
    },
    getFilters() {
      let filter_string = "&limit=5000";
      if (this.filterInput.from_date) {
        filter_string += `&from_date=${moment(this.filterInput.from_date).format("YYYY-MM-DD")}`;
      }
      if (this.filterInput.to_date) {
        filter_string += `&to_date=${moment(this.filterInput.to_date).format("YYYY-MM-DD")}`;
      }
      return filter_string.substring(1);
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    resetFilters() {
      this.filterInput = {
        from_date: null, // Default date inputs (YYYY-MM-DD format)
        to_date: null,
        status: '', // Reset dropdown
      };
      this.getServiceTickets();
    },
      hideDateInfo(){
               this.showDateInfo = false;
      }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <!-- Filter Section -->
        <div class="card">
          <div class="card-body">
        <div class="filter-section mb-4">
          <b-form @submit.prevent="applyFilter">
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <b-form-group label="Start Date" label-for="formrow-from_date-input" class="mb-3">
                  <date-picker
                      v-model="filterInput.from_date"
                      append-to-body
                      lang="en"
                      confirm
                      format="YYYY-MM-DD"
                      @input="hideDateInfo"
                  ></date-picker>


                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-3">
                <b-form-group label="End Date" label-for="formrow-to_date-input" class="mb-3">
                  <date-picker
                      v-model="filterInput.to_date"
                      append-to-body
                      lang="en"
                      confirm
                      format="YYYY-MM-DD"
                      @input="hideDateInfo"
                  ></date-picker>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-3">
                <b-form-group label="Status" label-for="formrow-status-input" class="mb-3">
                  <b-form-select
                      v-model="filterInput.status"
                      :options="statusOptionsFilter"
                      class="form-control"
                      @input="hideDateInfo"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="col d-inline-flex">
              <button type="button" class="btn btn-success me-2 w-lg" @click="applyFilters">Filter</button>
              <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
            </div>
<!--            <b-button  @click="applyFilters" type="submit" variant="primary" class="mt-2">-->
<!--              Apply Filter-->
<!--            </b-button>-->
<!--            <b-button variant="secondary" @click="resetFilters" class="mt-2 ml-2">-->
<!--              Reset Filters-->
<!--            </b-button>-->
          </b-form>
        </div>
        <p id="date_info" class="text-muted mt-3 mb-0 text-left" v-if="showDateInfo">
              <span class=" me-1">
                Service Tickets from the last 7 days
              </span>
        </p>
            </div>
          </div>

        <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center fw-normal">
                  Show&nbsp;
                  <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center fw-normal">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>

        <!-- Table Section -->
        <div class="table-responsive">
          <b-table
              table-class="table-responsive mb-0"
              :busy="isBusy"
              :items="tableData"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              show-empty
              empty-text="No Tickets Found"
          >
            <template #cell(statusActions)="row">
              <!--              <b-button size="sm" @click="openModal(row.item)">View</b-button>-->
              <!--              <b-button size="sm" variant="warning" @click="openEditModal(row.item)">Edit</b-button>-->
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <a
                      href="javascript:void(0);"
                      @click="openStatusModal(row.item)"
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit Status"
                  >
                    <i class="uil uil-pen font-size-18"></i>
                  </a>
                </li>
              </ul>

            </template>
            <!-- Add a slot for the actions column -->
            <template #cell(actions)="row">
<!--              <b-button size="sm" @click="openModal(row.item)">View</b-button>-->
<!--              <b-button size="sm" variant="warning" @click="openEditModal(row.item)">Edit</b-button>-->
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <a
                      href="javascript:void(0);"
                      @click="openModal(row.item)"
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="View Order"
                  >
                    <i class="uil uil-eye font-size-18"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                      href="javascript:void(0);"
                      @click="openEditModal(row.item)"
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit Order"
                  >
                    <i class="uil uil-pen font-size-18"></i>
                  </a>
                </li>
              </ul>

            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <ServiceTicketModal
              :ticket_id="selectedTicketId"
              v-model="showModal"
          />
          <EditServiceTicket
              :ticketDetails="selectedTicketDetails"
              ref="editModal"
          />
          <StatusServiceTicket
              :ticketDetails="selectedTicketDetails"
          />

          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="my-0"
          ></b-pagination>
        </div>
      </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.custom-datepicker .bv-popover {
  font-size: 12px; /* Adjust font size */
  width: 250px; /* Adjust the width of the dropdown */
  height: auto; /* Adjust height if necessary */
}

.custom-datepicker .bv-calendar {
  font-size: 14px; /* Change the font size inside the calendar */
}

.custom-datepicker .bv-calendar td {
  padding: 5px; /* Adjust padding between the days */
}

.custom-datepicker .bv-popover-arrow {
  display: none; /* Optionally hide the arrow */
}
</style>