var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"status_service_ticket","title":"Edit Service Ticket","size":"xl","centered":""},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',{attrs:{"label":"Status"}},[_c('b-form-select',{attrs:{"options":[
      { value: 0, text: '0-Сервис тикетот е одбиен' },
      { value: 1, text: '1-Нов сервис тикет' },
      { value: 2, text: '2-Сервис тикетот е отворен' },
      { value: 3, text: '3-Уредот е спремен за сервисирање' },
      { value: 4, text: '4-Уредот се сервисира' },
      { value: 5, text: '5-Уредот е сервисиран' },
      { value: 6, text: '6-Уредот чека на испорака' },
      { value: 7, text: '7-Уредот е доставен' }
    
  ]},model:{value:(_vm.editableTicket.status),callback:function ($$v) {_vm.$set(_vm.editableTicket, "status", $$v)},expression:"editableTicket.status"}})],1)],1)])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveChanges}},[_vm._v("Save Changes")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }