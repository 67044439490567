<script>
import ServiceTicketService from "@/services/ServiceTickets";

export default {
  props: ['ticket_id', 'value'],
  data() {
    return {
      ticketDetails: null, // Stores the ticket details
      showLoader: false,
      // statusOptions: {
      //   0: '0-Rejected Service ticket',
      //   1: '1-New Service ticket',
      //   2: '2-Opened service order',
      //   3: '3-Device ready for service',
      //   4: '4-Device is being serviced',
      //   5: '5-Device is serviced',
      //   6: '6-Device waiting for delivery',
      //   7: '7-Device delivered'
      // }
      statusOptions: {
        0: '0-Сервис тикетот е одбиен',
        1: '1-Нов сервис тикет',
        2: '2-Сервис тикетот е отворен',
        3: '3-Уредот е спремен за сервисирање',
        4: '4-Уредот се сервисира',
        5: '5-Уредот е сервисиран',
        6: '6-Уредот чека на испорака',
        7: '7-Уредот е доставен'
      }
    };
  },
  computed: {
    showModal: {
      get() {
        return this.value; // Sync with parent
      },
      set(val) {
        this.$emit('input', val); // Update parent
      },
    },
    ticketStatus() {
      return this.statusOptions[this.ticketDetails.status] || 'Unknown status';
    }
  },
  methods: {
    async getTicketDetails() {
      this.showLoader = true;
      try {
        // const response = await fetch(`http://scapi.net/merchandise/serviceTicket/${this.ticket_id}`, {
        //   headers: {
        //     'SCAPI-KEY': 'RIV-01-RIVERSHOP-202202',
        //   },
        // });
        const response = await ServiceTicketService.getServiceTicket(this.ticket_id);

        // console.log(`this ticket id is ${this.ticket_id}`)
        const data = response.data;
        this.ticketDetails = data[0]; // Bind ticket data

      } catch (error) {
        console.error("Failed to fetch service ticket details:", error);
        this.ticketDetails = null; // Reset on failure
      } finally {
        this.showLoader = false;
        // console.log(this.ticketDetails);
      }
    },
    resetProps() {
      this.ticketDetails = null; // Clear data when modal is hidden
    },
  },
};
</script>

<template>
  <b-modal
      @shown="getTicketDetails"
      @hidden="resetProps"
      v-model="showModal"
      id="view_service_ticket"
      title="View Service Ticket"
      title-class="font-18"
      size="xl"
      centered
  >
    <template v-if="!showLoader && ticketDetails">
      <div class="row">
        <div class="col-sm-6">
          <p><strong>First Name:</strong> {{ ticketDetails.first_name }}</p>
          <p><strong>Last Name:</strong> {{ ticketDetails.last_name }}</p>
          <p><strong>Guarantee:</strong> {{ ticketDetails.guarantee === 1 ? 'Yes' : 'No' }}</p>
          <p><strong>Company Name:</strong> {{ ticketDetails.company_name }}</p>
          <p><strong>Phone:</strong> {{ ticketDetails.phone }}</p>
          <p><strong>Description:</strong> {{ ticketDetails.description }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>Email:</strong> {{ ticketDetails.email }}</p>
          <p><strong>City:</strong> {{ ticketDetails.city }}</p>
          <p><strong>Address:</strong> {{ ticketDetails.address }}</p>
          <p><strong>Product Model:</strong> {{ ticketDetails.product_model }}</p>
          <p><strong>Product Serial:</strong> {{ ticketDetails.product_serial }}</p>
          <p><strong>Fiscal Slip:</strong>
          <a :href="ticketDetails.fiscal_slip" target="_blank">{{ticketDetails.fiscal_slip }}</a></p>
          <p>
            <strong>Guarantee Scan:</strong>
            <a v-if="ticketDetails.guarantee_list_scan" :href="ticketDetails.guarantee_list_scan" target="_blank">
              {{ ticketDetails.guarantee_list_scan }}
            </a>
            <span v-else>Not Uploaded</span>
          </p>
          <p><strong>Status:</strong> {{ ticketStatus }}</p>
          <p><strong>Created:</strong> {{ ticketDetails.created}}</p>
          <p><strong>Modified:</strong> {{ ticketDetails.timestamp}}</p>
        </div>
      </div>
 
    </template>
    <template v-else-if="showLoader">
      <div class="d-flex justify-content-center">
        <b-spinner large></b-spinner>
      </div>
    </template>
    <template v-else>
      <div class="text-center text-muted">No ticket details available.</div>
    </template>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="showModal = false">Close</b-button>
    </template>
  </b-modal>
</template>
